<div class="container-fluid">
    <div class="card" *ngIf="showImage">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <section>
                     <!-- <ks-carousel [id]="101" [images]="imagesRect">
                    </ks-carousel> -->
                    <img  [src]="event.image" class="img-fluid blur-up lazyload bg-img" alt="">
                </section>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <h2>{{event.title}}</h2>
                    <div class="product-action-buttons">
                        <button class="btn btn-secondary m-r-10" (click)="goBack()">Atrás</button>
                        <button class="btn btn-secondary m-r-10" (click)="goToTicket()">Previsualizar Ticket</button>
                        <button class="btn btn-primary" (click)="editEvent(event)">Editar</button>
                    </div>
                </div>
                    
                    <hr>
                    <h6 class="product-title">Informacion</h6>
                    <p class="mb-0"><b class="product-title">Ciudad/pueblo:</b> {{event.location}}</p>
                    <p class="mb-0"><b class="product-title">Establecimiento:</b> {{event.establishment}}</p>
                    <p class="mb-0"><b class="product-title">Dirección:</b>{{event.address}}</p>
                    <h6 class="product-title">Descripcion del evento</h6>
                    <p class="mb-0">{{event.description}}</p>
                    <div class="product-price digits mt-2">
                      <h3>{{ fecha_evento}}</h3>
                    </div>
                   
                    <hr>
                    <h6 class="product-title size-text">Tickets del evento</h6>
        
                    <div class="size-box">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Titulo</th>
                              <th>Descripcion</th>
                              <th>Precio</th>
                              <th>C.Inicial</th>
                              <th>C.Restante</th>
                              <th>Estado</th>
                              <!-- Agrega más columnas según sea necesario -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let ticket of event.tickets">
                              <td>{{ ticket.title }}</td>
                              <td>{{ ticket.description }}</td>
                              <td>{{ ticket.price }}€</td>
                              <td>{{ ticket.total_amount }}</td>
                              <td>{{ ticket.amount }}</td>
                              <td>{{ ticket.available }}</td>
                              <!-- Agrega más columnas según sea necesario -->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      
                    <hr>
                    <div class="product-info">
                      <h6 class="product-title">Time Reminder</h6>
                      <div class="timer">
                        <p id="demo"><span>{{diasRestantes}} <span class="padding-l">:</span> <span class="timer-cal">Dias</span>
                        </span><span>{{horasRestantes}} <span class="padding-l">:</span> <span class="timer-cal">Hrs</span>
                        </span><span>{{minutosRestantes}} <span class="padding-l">:</span> <span class="timer-cal">Min</span>
                        </span><span>{{segundosRestantes}} <span class="timer-cal">Seg</span></span>
                    </p>
                      </div>
                    </div>
                    
                    <div class="product-info">
                      <h6 class="product-title">Enlace web del evento</h6>
                      <div>
                        <p><a href="https://eticketame.com/event/{{event.url_web}}">https://eticketame.com/event/{{event.url_web}}</a></p>

                      </div>
                    </div>
                    <!-- <div class="m-t-15">
                        <button class="btn btn-primary m-r-10" type="button">Add To Cart</button>
                        <button class="btn btn-secondary" type="button">Buy Now</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>