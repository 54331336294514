import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { EventService } from 'src/app/shared/service/event.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  id = localStorage.getItem('auth_user');
  constructor(
    private eventService: EventService
  ) {
    Object.assign(this, { doughnutData, pieData })
  }


  ngOnInit() {
    const userObject = JSON.parse(this.id);
    this.id=userObject.id_professional
    this.eventService.editarEstadoEvento(this.id).subscribe(
      data => {
        console.log(data)
      },
      error => console.error('Error al cargar eventos:', error)
    )
  }

}
