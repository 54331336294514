import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from 'src/environments/environment';
import { LoginRequest, LoginResponse, AuthUser} from '../models/auth.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private currentUser: AuthUser = null;

  constructor(
    private http: HttpClient,
    private router: Router
    ) {
    const savedUser = localStorage.getItem('auth_user');
    if (savedUser) {
      this.currentUser = JSON.parse(savedUser);
    }
  }

  login(data: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${BASE_URL_API}/login`, data)
      .pipe(
        tap(response => this.setUser(response.userDetails)),
        catchError(this.handleError)
      );
  }

  logout() {
    localStorage.removeItem('auth_user');
    localStorage.clear();
    this.router.navigate(['login']);
  }

  getCurrentUser(): AuthUser {
    return this.currentUser;
  }

  setUser(user: AuthUser): void {
    this.currentUser = user;
    localStorage.setItem("auth_user", JSON.stringify(user));
  }
  

  isAdmin(): boolean {
    const user = this.getCurrentUser();
    return user && user.rol === 'admin';
  }

  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
  }
}
