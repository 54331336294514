// usuarios.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from 'src/environments/environment';
import { Usuario } from '../models/usuario.model';  // Asegúrate de tener un modelo de Usuario
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('auth_user') ? JSON.parse(localStorage.getItem('auth_user')).token : '';
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }

  obtenerUsuarios(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${BASE_URL_API}/usuarios`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerCodigoApp(id:any){
    return this.http.get<any>(`${BASE_URL_API}/professional/${id}`, { headers: this.getHeaders() })
    .pipe(
      catchError(this.handleError)
    );
  }

  obtenerUsuario(id:any): Observable<any[]> {
    return this.http.get<any[]>(`${BASE_URL_API}/user/professional/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerProf(id:any): Observable<any[]> {
    return this.http.get<any[]>(`${BASE_URL_API}/user/prof/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarProf(prof: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/user/prof/${prof.id}`, prof, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerEncargados(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${BASE_URL_API}/usuarios/encargados`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  registrarUsuario(usuario: Usuario): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/usuarios/register`, usuario, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarUsuario(usuario: Usuario): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/usuarios/${usuario.usuario_id}`, usuario, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  restablecerContrasenna(usuario: Usuario, nuevaPassword: string): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/usuarios/reset-password/${usuario.usuario_id}`, { password: nuevaPassword }, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  borrarUsuario(usuario: Usuario): Observable<any> {
    return this.http.delete<any>(`${BASE_URL_API}/usuarios/${usuario.usuario_id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  enviarSoporte(data: any){
    console.log(data)
    return this.http.post<any>(`${BASE_URL_API}/nodemailer/ayuda`, data,{ headers: this.getHeaders() } )
    .pipe(
      catchError(this.handleError)
    );
  }


  descargarArchivo(url: string, nombreArchivo: string): void {
    this.http.get(url, { responseType: 'blob' })
      .subscribe((data: Blob) => {
        // Crear un Blob con los datos recibidos
        const blob = new Blob([data], { type: 'application/octet-stream' });

        // Guardar el archivo usando file-saver
        saveAs(blob, nombreArchivo);
      }, error => {
        console.error('Error al descargar el archivo:', error);
      });
  }


  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
  }
}
