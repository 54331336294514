// event.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BASE_URL_API } from 'src/environments/environment';
import { Evento } from '../models/event.model';  // Asegúrate de importar el modelo correcto
import { Ticket } from '../models/ticket.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private eventToEdit: any;
  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('auth_user') ? JSON.parse(localStorage.getItem('auth_user')).token : '';
    // console.log(token)
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }

  obtenerEventos(): Observable<Evento[]> {
    return this.http.get<Evento[]>(`${BASE_URL_API}/event`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  obtenerEventosProf(id:any): Observable<Evento[]> {
    return this.http.get<Evento[]>(`${BASE_URL_API}/event/professional/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  obtenerEventosFacturas(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache/pursache/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  obtenerFacturasEvento(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache/ventas/${id}`, { headers: this.getHeaders() })
    .pipe(
      catchError(this.handleError)
    );

  }

  obtenerEventosEstadisticas(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache/estadisticas/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerEventosEstadisticasMensuales(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache/estadisticas/mes/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerEventosEstadisticasAnuales(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache/estadisticas/anuales/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerEventosVenta(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache/pursache/venta/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerCodigosVenta(id:any): Observable<[]> {
    return this.http.get<[]>(`${BASE_URL_API}/event_ticket_pursache_code/event_ticket_pursache/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerEvento(id:any): Observable<Evento[]> {
    return this.http.get<Evento[]>(`${BASE_URL_API}/event/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }


  crearEvento(evento: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/event`, evento, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarEvento(evento: Evento): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/event/${evento.id}`, evento, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarEstadoEvento(id: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/event/estado/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarTicket(ticket: Ticket): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/event_ticket/${ticket.id}`, ticket, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  crearTicket(ticket: Ticket): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/event_ticket`, ticket, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  borrarEvento(id: any): Observable<any> {
    return this.http.put<any>(`${BASE_URL_API}/event/visible/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  
  borrarTicket(id: any): Observable<any> {
    return this.http.delete<any>(`${BASE_URL_API}/event_ticket/${id}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  setEventToEdit(event: any) {
    this.eventToEdit = event;
  }

  getEventToEdit() {
    return this.eventToEdit;
  }

  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
  }
}
