import { Component, OnInit } from '@angular/core';
import { UsuariosService } from 'src/app/shared/service/usuarios.service';
import { Router,ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  professional:any;
  user:any;
  nombreEmpresa:any;
  descripcionEmpresa:any;
  telefonoEmpresa:any;
  ciudadEmpresa:any;
  provinciaEmpresa:any;
  direccionEmpresa:any;
  municipioEmpresa:any;
  codigo_postalEmpresa:any;
  terminosEmpresa:any;

  constructor(
    private usuariosService:UsuariosService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ){

  }

  ngOnInit() {
    this.professional=localStorage.getItem('professional')
    this.user = localStorage.getItem('usuario')

    const profObject = JSON.parse(this.professional);
    const userObject = JSON.parse(this.user);

    this.professional=profObject
    this.user=userObject

    this.nombreEmpresa = this.professional.company
    this.descripcionEmpresa = this.professional.description
    this.telefonoEmpresa = this.professional.phone
    this.ciudadEmpresa = this.professional.country
    this.provinciaEmpresa = this.professional.province
    this.direccionEmpresa = this.professional.address
    this.municipioEmpresa = this.professional.municipality
    this.codigo_postalEmpresa = this.professional.postal_code
    this.terminosEmpresa = this.professional.terms
   }

   putProf(){
    var prof= {
      id: this.professional.id,
      company:this.nombreEmpresa,
      description: this.descripcionEmpresa,
      phone:this.telefonoEmpresa,
      country:this.ciudadEmpresa,
      province:this.provinciaEmpresa,
      address:this.direccionEmpresa,
      municipality:this.municipioEmpresa,
      postal_code:this.codigo_postalEmpresa,
      terms:this.terminosEmpresa
    }
    console.log(prof)
    this.usuariosService.editarProf(prof).subscribe(
      data=>{
        console.log(data)
        Swal.fire({ icon: 'success', title: 'Correcto', text: 'Se ha editado el perfil correctamente.' });
        this.router.navigate(['/settings/profile']);
      },
      error =>{ 
        console.error('Error al actualizar:', error)
        Swal.fire({ icon: 'error', title: 'Error', text: 'Ha ocurrido un error al actualizar el perfil.' });
      }
    )
   }

   goBack(): void {
    this.router.navigate(['/settings/profile']);
    // Usa el servicio Location para volver atrás
  }
}
