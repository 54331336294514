import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/shared/service/event.service';
import { Router,ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [NgbRatingConfig]
})
export class EventComponent implements OnInit{
  public closeResult: string;
  public counter: number = 1;
  currentRate = 8;
  fecha_evento:any
  public showImage: boolean = false;
  eventoParseado:any
  diasRestantes:any = 0
  horasRestantes:any = 0
  minutosRestantes:any = 0
  segundosRestantes:any = 0

  event:any = [];

  constructor(private modalService: NgbModal,private activatedRoute: ActivatedRoute, config: NgbRatingConfig,
    private eventService: EventService, private router: Router
    ) {
    config.max = 5;
    config.readonly = false;

  }

  async ngOnInit() {
    var eventoSeleccionado = localStorage.getItem('event-seleccionado');

    this.eventoParseado = JSON.parse(eventoSeleccionado);
    console.log(this.eventoParseado)
    await this.obtenerEvents(this.eventoParseado)
  }

  async obtenerEvents(event:any){
    await this.eventService.obtenerEvento(event.id)
      .subscribe(
        data => {
          this.event = data;
          this.fecha_evento =this.event.date
          this.fecha_evento = this.formatearFechaHora(this.fecha_evento);
          console.log(this.event)
          localStorage.setItem('professional_id', JSON.stringify(this.event.id_professional));
          localStorage.setItem('compra_pre', JSON.stringify(this.event));
          this.showImage = true;
          this.calcularTiempoRestante();
        },
        error => console.error('Error al cargar evento:', error)
      );
  }

  formatearFechaHora(fechaHora: string): string {
    // Crear un objeto Date a partir de la cadena de fecha y hora
    const fechaHoraObj = new Date(fechaHora);

    // Obtener el año, mes y día de la fecha
    const year = fechaHoraObj.getFullYear();
    const month = ('0' + (fechaHoraObj.getMonth() + 1)).slice(-2);
    const day = ('0' + fechaHoraObj.getDate()).slice(-2);

    // Obtener la hora y los minutos
    const hours = (('0' + (fechaHoraObj.getHours()-2)).slice(-2));
    const minutes = ('0' + fechaHoraObj.getMinutes()).slice(-2);

    // Devolver la fecha y la hora formateadas
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  calcularTiempoRestante(): void {
    const fechaEvento = new Date(this.event.date).getTime();
    const fechaActual = new Date().getTime();
    const tiempoRestante = fechaEvento - fechaActual;

    if (tiempoRestante > 0) {
      this.diasRestantes = Math.floor(tiempoRestante / (1000 * 60 * 60 * 24));
      this.horasRestantes = Math.floor((tiempoRestante % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutosRestantes = Math.floor((tiempoRestante % (1000 * 60 * 60)) / (1000 * 60));
      this.segundosRestantes = Math.floor((tiempoRestante % (1000 * 60)) / 1000);
    } else {
      // El evento ya ha pasado
      this.diasRestantes = 0;
      this.horasRestantes = 0;
      this.minutosRestantes = 0;
      this.segundosRestantes = 0;
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  goBack(): void {
    this.router.navigate(['/events']);
    // Usa el servicio Location para volver atrás
  }

  goToTicket(): void {
    this.router.navigate(['./', 'ticket'], { relativeTo: this.activatedRoute });
    // this.router.navigate(['/events/event/edit']);
  }

  editEvent(event: any): void {
    this.eventService.setEventToEdit(event)
    // localStorage.setItem('event-editar', JSON.stringify(event));
    this.router.navigate(['./', 'edit'], { relativeTo: this.activatedRoute });
    // this.router.navigate(['/events/event/edit']);
  }


}
