<!-- Container-fluid starts-->
<div class="container-fluid" >
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body" *ngIf="user || professional">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/man.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.name}} {{user?.surname}}</h5>
                        <span>{{user?.email}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel" labelledby="top-profile-tab">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="f-w-600"> Empresa</h5>
                            <button class="btn btn-secondary m-r-10" (click)="goBack()">Atrás</button>
                            <button class="btn btn-primary" (click)="putProf()">Guardar</button>
                        </div>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nombre:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="nombreEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Descripcion:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="descripcionEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Telefono:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="telefonoEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Ciudad:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="ciudadEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Provincia:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="provinciaEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Direccion:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="direccionEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Municipio:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="municipioEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Codigo Postal:</td>
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="codigo_postalEmpresa" type="text" required="">
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Terminos legales:</td>
                                        <div class="form-group">
                                            <textarea required class="mb-3 mw-100 w-100" placeholder="Mensaje de recordatorio la cita"
                                            [(ngModel)]="terminosEmpresa" name="terminosEmpresa" nbInput fullWidth></textarea>
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
