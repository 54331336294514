<!-- Container-fluid starts-->
<div class="container-fluid" >
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body" *ngIf="user || professional">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/man.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.name}} {{user?.surname}}</h5>
                        <span>{{user?.email}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel" labelledby="top-profile-tab">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="f-w-600"> Empresa</h5>
                            <button class="btn btn-primary" (click)="editEvent()">Editar</button>
                        </div>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nombre:</td>
                                        <td>{{professional?.company}}</td>
                                    </tr>
                                    <tr>
                                        <td>Descripcion:</td>
                                        <td>{{professional?.description}}</td>
                                    </tr>
                                    <tr>
                                        <td>Telefono:</td>
                                        <td>{{professional?.phone}}</td>
                                    </tr>
                                    <tr>
                                        <td>Ciudad:</td>
                                        <td>{{professional?.country}}</td>
                                    </tr>
                                    <tr>
                                        <td>Provincia:</td>
                                        <td>{{professional?.province}}</td>
                                    </tr>
                                    <tr>
                                        <td>Direccion:</td>
                                        <td>{{professional?.address}}</td>
                                    </tr>
                                    <tr>
                                        <td>Municipio:</td>
                                        <td>{{professional?.municipality}}</td>
                                    </tr>
                                    <tr>
                                        <td>Codigo Postal:</td>
                                        <td>{{professional?.postal_code}}</td>
                                    </tr>
                                    <tr>
                                        <td>Terminos legales:</td>
                                        <td>{{professional?.terms}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->