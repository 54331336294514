import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthUser, LoginRequest } from 'src/app/shared/models/auth.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  public active = 1;
  alerta = false;
  msgError = ''
  correo : string = ''
  password : string = ''

  constructor(
    private authService: AuthService,
    private router: Router) {

  }

  owlcarousel = [
    {
      title: "Eticketame",
      desc: "Panel eticketame",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };




  ngOnInit() {
  }


  onSubmit() {
    const loginData: LoginRequest = {
      correo: this.correo,     
      password: this.password
    };

    this.authService.login(loginData)
    .subscribe(
      response => {
        const authUser: AuthUser = {
          token: response.token,
          nombre: response.userDetails.nombre,
          correo: response.userDetails.correo,
          rol: response.userDetails.rol,
          id_professional: response.userDetails.id_professional
        };
        localStorage.setItem("auth_user", JSON.stringify(authUser));
        switch (authUser.rol) {
          case 'admin':
              this.router.navigate(["./statistics"]);
              break;
          case 'encargado':
              this.router.navigate(["./statistics"]);
              break;
          case 'trabajador':
              this.router.navigate(["./statistics"]);
              break;
          default:
              this.router.navigate(["./login"]);
              break;
      }
      },
      error => {
        this.msgError = error;
        this.mostrarAlerta(); 
      }
    );
}


    cerrarAlerta() {
      this.alerta = false;
    }
    
    mostrarAlerta() {
      this.alerta = true;
      
      setTimeout(() => {
        this.cerrarAlerta();
      }, 2000);
    }
    
}
