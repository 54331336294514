import { HelpModule } from './../../components/help/help.module';
import { Stripe } from '@stripe/stripe-js';
import { EventComponent } from './../../components/events/event/event.component';
import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { RoleGuard } from '../guards/role.guard';

export const content: Routes = [


  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },

  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
 
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },

  //NUEVAS RUTAS
  {
    path: 'events',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'rrpp',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/rrpp/rrpp.module').then(m => m.RrppModule)
  },
  {
    path: 'clients',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/clients/clients.module').then(m => m.ClientsModule)
  },
  {
    path: 'billing',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'statistics',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/statistics/statistics.module').then(m => m.StatisticsModule)
  },
  {
    path: 'stripe',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/stripe/stripe.module').then(m => m.StripeModule)
  },
  {
    path: 'scan',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/scan/scan.module').then(m => m.ScanModule)
  },
  {
    path: 'help',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['admin']
    },
    loadChildren: () => import('../../components/help/help.module').then(m => m.HelpModule)
  },
];