import { Component, OnInit } from '@angular/core';
import { UsuariosService } from 'src/app/shared/service/usuarios.service';
import { Router,ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;
  id = localStorage.getItem('auth_user');
  professional:any
  user:any;

  constructor(
    private usuariosService: UsuariosService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    this.obtenerProf()
   }

  obtenerProf(){
    const userObject = JSON.parse(this.id);
    this.id=userObject.id_professional
    this.usuariosService.obtenerProf(this.id).subscribe(
      data => {
        this.professional = data[0];
        console.log(this.professional)
        
      },
      error => console.error('Error al cargar eventos:', error)
    );
    this.usuariosService.obtenerUsuario(this.id).subscribe(
      data => {
        this.user = data[0];
        console.log(this.user)
      },
      error => console.error('Error al cargar eventos:', error)
    );
  }

  editEvent(): void {
    localStorage.setItem('usuario', JSON.stringify(this.user));
    localStorage.setItem('professional', JSON.stringify(this.professional));
    this.router.navigate(['./', 'edit'], { relativeTo: this.activatedRoute });
}

}
